import React, { useRef, useState } from 'react';
import { Button } from 'antd';
import MyQuill from 'react-quill';
import './question-type.scss';
import { getResourceRealPath } from '../../../../utils/common';
const QuestionType = ({ content }) => {
    const [playing, setPlaying] = useState();
    const audioRef = useRef();

    return (
        <div className="question-type">
            {content?.type === 'File' && (
                <div>
                    {playing ? (
                        <Button className="popup-button">
                            {window.__lang('record_playing...')}
                        </Button>
                    ) : (
                        <Button
                            onClick={() => audioRef.current.play()}
                            className="popup-button"
                        >
                            {window.__lang('play')}
                        </Button>
                    )}
                    <audio
                        onPlay={() => setPlaying(true)}
                        onPause={() => setPlaying(false)}
                        ref={audioRef}
                        src={content?.data}
                    />
                </div>
            )}
            {(content?.type === 'Open Answer' || content?.type === 'Draft') && (
                <MyQuill
                    readOnly
                    theme={'bubble'}
                    value={content?.data ?? ''}
                    onChange={() => {}}
                    className="answer-box"
                />
            )}
        </div>
    );
};

export default QuestionType;

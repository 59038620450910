import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import SiteLayout from './SiteLayout';
import FirstLoginPage from '../../pages/FirstLoginPage';
import { retrieveDecryptedUserFromLocalStorage } from '../../utils/common';

const ProtectedRoute = props => {
    const user = retrieveDecryptedUserFromLocalStorage();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const { component, ...rest } = props;

    return (
        <Route
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...rest}
            render={renderProps => {
                if (user) {
                    // Restrict access to specific paths
                    if (
                        props?.path === '/admin/empty-searches-audits' &&
                        user.role !== 'administrator'
                    ) {
                        return (
                            <Redirect
                                to={{
                                    pathname: '/not-authorized',
                                }}
                            />
                        );
                    }
                    if (
                        user.privacyConfirmDate !== null ||
                        user.role !== 'learner'
                    ) {
                        return (
                            <SiteLayout
                                component={component}
                                user={user}
                                isMobile={isMobile}
                                /* eslint-disable-next-line max-len */
                                /* eslint-disable-next-line react/jsx-props-no-spreading */
                                {...renderProps}
                            />
                        );
                    }
                    return (
                        <SiteLayout
                            component={FirstLoginPage}
                            user={user}
                            isMobile={isMobile}
                            /* eslint-disable-next-line max-len */
                            /* eslint-disable-next-line react/jsx-props-no-spreading */
                            {...props}
                        />
                    );
                }
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                        }}
                    />
                );
            }}
        />
    );
};

export default ProtectedRoute;

import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Col, Form, Modal, Row, Spin, Tooltip, Dropdown, Badge} from 'antd';
import {SettingOutlined} from '@ant-design/icons';
import {Link, useLocation} from 'react-router-dom';
import Avatar from 'react-avatar';
import {SiteLayoutStyles} from '../../styles/SiteLayout.style';
import './Header.scss';
import HeaderProfileMenu from './HeaderProfileMenu';
import HeaderMySchoolMenu from './HeaderMySchoolMenu';
import HeaderNotificationMenu from './HeaderNotificationMenu';
import NotificationsBell from './NotificationsBell';
import TextArea from 'antd/lib/input/TextArea';
import {axiosDefaultClient} from '../../settings/axios.settings';
import {useDispatch, useSelector} from 'react-redux';
import ReactAvatar from 'react-avatar';
import HeaderSetting from './HeaderSetting';
import {defaultAvatar} from "../Shared/SVG/learnerIcons";
import latestMessagesAvatar
    from "../Messages/MessagesScreen/Components/businessman-character-avatar-isolated_24877-60111.jpg";
import {messagesActions} from "../../redux/actions/messages.actions";
import defaultLogo from '../../../public/img/defaultLogo.jpg'
import { getResourceRealPath, retrieveDecryptedSiteFromLocalStorage, retrieveDecryptedUserFromLocalStorage, stripHtml } from '../../utils/common';
import SsoButton from '../SsoButton';
import DOMPurify from 'dompurify';

const styles = SiteLayoutStyles;

const HeaderMenuDesktop = props => {
    const location = useLocation();
    const {pathname} = location;
    const [showDropdown, setShowDropdown] = useState(false);
    const [reportModalVisible, setReportModalVisible] = useState(false);
    const [reportText, setReportText] = useState('');
    const [reportRequestLoading, setReportRequestLoading] = useState(false);
    const {notifications, unreadCount} = props;
    const user = retrieveDecryptedUserFromLocalStorage();
    const loggedUser = retrieveDecryptedUserFromLocalStorage();
    const site = retrieveDecryptedSiteFromLocalStorage();
    const settings = window.settings;
    // const logo =loggedUser ?`${loggedUser.site_logo}` :JSON.parse(localStorage.getItem('logo'))
    const logo = loggedUser?.site_logo ?? settings?.default_logo;
    const {latestMessages} = useSelector(state => state.messages)
    const dispatch = useDispatch();
    const [logoFromParentUserGroup, setLogoFromParentUserGroup] = useState(null)

    useEffect(() => {
        const loggedUser = retrieveDecryptedUserFromLocalStorage();
        const site = retrieveDecryptedSiteFromLocalStorage();
        if (loggedUser.role === 'trainer') {
            if (loggedUser?.trainer_groups.length > 0) {
                let logos = [];
                for (let userGroup of loggedUser.trainer_groups) {
                    if (userGroup.displayLogoForTrainers === 1) {
                        if (userGroup.logo_s3) {
                            logos.push(userGroup.logo_s3);
                        } else if (userGroup.parent_id) {
                            const parent = loggedUser?.trainer_groups.filter(
                                (obj) =>
                                    obj.userGroupID === userGroup.parent_id,
                            );
                            if (parent.logo) logos.push(parent.logo);
                        }
                    }
                }
                setLogoFromParentUserGroup([...new Set(logos)]);
            } else {
                setLogoFromParentUserGroup([site.largeLogo_s3]);
            }
        }
    }, []);

    useEffect(() => {
        dispatch(messagesActions.fetchLatestMessages())
    }, [])

    const submitIssue = () => {
        setReportRequestLoading(true);
        const client = axiosDefaultClient;
        client
            .post('/api/issues', {
                userID: user.userID,
                page: pathname,
                description: reportText,
            })
            .then(response => {
                Modal.info({
                    title: response.data.message,
                    content: (
                        <div>
                            You can view the issue, add a screenshot or add any
                            comment by clicking{' '}
                            <a target="_blank" href={response.data.link}>
                                here
                            </a>
                        </div>
                    ),
                });
                setReportRequestLoading(false);
                setReportModalVisible(false);
            })
            .catch(error => {
                Modal.error({
                    title: 'Error',
                    content: 'Unable to create issue.',
                });
            });
    };

    const messagesStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        //align-items: space-between;

        border: "1px solid #f5f6fa",
        marginBottom: "10px",
        borderRadius: "5px",
        padding: "7px",
        cursor: "pointer",
    }

    const displayMessage = (item) => {
        const sanitizedMessage = DOMPurify.sanitize(item?.message); // Sanitize HTML
        const plainTextMessage = stripHtml(sanitizedMessage); // Convert to plain text
        return plainTextMessage;
    };

    const messages = (
        <div style={{width: 300, minHeight: 120, backgroundColor: "#fff"}} className="learner-messages">
            <div className="msg-header">
                <h3>{window.__lang('messages')}</h3>
                <Link to={'/trainer/messages'}>{window.__lang('see_all')}</Link>
            </div>
            {/*<div className="latest-messages-list">*/}
            <div style={messagesStyle}>
                {latestMessages.length > 0 && latestMessages.map(item => (
                    item.user_type === "receiver" && (
                        <Link to={'/trainer/messages'} style={{color: "#131523", fontSize: 12, fontWeight: 500}}>
                            <div style={{
                                minHeight: 60,
                                padding: 7,
                                borderRadius: 7,
                                display: "flex",
                                backgroundColor: item.is_read === false ? "#f0f1fc" : "#fafafa",
                            }}>
                                {item?.user?.image === null ? (
                                    <ReactAvatar
                                        color={ReactAvatar.getRandomColor('sitebase', ['red', 'green', 'blue'])}
                                        name={item?.user?.name}
                                        size={45}
                                        className={'contact-avatar'}
                                    />
                                ) : (
                                    <ReactAvatar
                                        color={ReactAvatar.getRandomColor('sitebase', ['red', 'green', 'blue'])}
                                        src={getResourceRealPath(item?.user?.image)}
                                        size={45}
                                        className={'contact-avatar'}
                                    />
                                )}
                                <div>
                                    {item.is_read === false ? <h3>{item?.user?.name}</h3> : <h4>{item?.user?.name}</h4>}
                                    {item.is_read === false ?
                                        <h4 > {displayMessage(item)} </h4>:
                                        <h5 >{displayMessage(item)} </h5>
                                    }
                                </div>
                            </div>
                        </Link>
                    )))}
                {!latestMessages.length && (
                    <div style={{height: 30, padding: 7, display: "flex", backgroundColor: "#f5f6fa"}}>
                        <h5>There are no messages</h5>
                    </div>
                )}
            </div>
            {/*<div className="latest-messages-list">*/}
            {/*    {latestMessages.map(item => (*/}
            {/*        <>*/}
            {/*            <img src={item.user.image !== null ? item.use.image : latestMessagesAvatar} alt={window.__lang(`${item.user.role}`)}*/}
            {/*                 style={{width: 50, height: 50, borderRadius: 50, marginRight: 10}}/>*/}
            {/*            <Link to={'/learner/messages'}>*/}
            {/*                {window.__lang(`${item?.thread?.subject.replace(/ /g, "_")}`)}*/}
            {/*            </Link>*/}
            {/*        </>*/}
            {/*    ))}*/}
            {/*</div>*/}
        </div>
    );

    return (
        <div style={{padding: '0px 10px'}}>
            <Modal
                visible={reportModalVisible}
                title="Report an issue"
                closable
                maskClosable
                onCancel={() => setReportModalVisible(false)}
                onOk={submitIssue}
            >
                {reportRequestLoading ? (
                    <div>
                        <Spin spinning/>
                    </div>
                ) : (
                    <Form>
                        <TextArea
                            onChange={e => setReportText(e.target.value)}
                        />
                    </Form>
                )}
            </Modal>
            <Row className="headerMenuDesktop">
                {loggedUser.role === 'trainer' ? (
                    logoFromParentUserGroup?.length > 0 &&
                    logoFromParentUserGroup?.map((logo, i) => (
                        <Col
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: 20,
                            }}
                            key={i}
                        >
                            <Link to="/">
                                <img
                                    className="logoImage"
                                    src={logo}
                                    alt="logo-image"
                                />
                            </Link>
                        </Col>
                    ))
                ) : (
                    <Col
                        flex={1}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Link to="/">
                            <img
                                className="logoImage"
                                src={site.largeLogo_s3}
                                alt="logo-image"
                            />
                        </Link>
                    </Col>
                )}
    
                <Col flex={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                    <div className={"trainer-message-icon"} style={{display: 'flex', justifyContent: 'end'}}>
                        {user?.role === 'trainer' && (
                            <ul>
                                {localStorage.getItem('ssoUser') && site?.ssoReturnUrl && <li>
                                    <SsoButton></SsoButton>
                                </li>}
                                <li>
                                    {latestMessages.find(msg => msg.is_read === false)?.is_read === false ? (
                                        <span
                                            style={{
                                                height: 16,
                                                width: 16,
                                                backgroundColor: "red",
                                                borderRadius: "50%",
                                                display: "inline-block",
                                                transform: "translate(40px, 5px)",
                                                position: "absolute",
                                                zIndex: 10,
                                            }}
                                        >
                                            <p
                                                style={{
                                                    display: "inline-block",
                                                    transform: "translate(5px, -25px)",
                                                    position: "absolute",
                                                    zIndex: 10,
                                                    color: "white",
                                                    fontSize: 12,
                                                }}
                                            >
                                                {latestMessages.filter(msg => msg.is_read === false).length}</p>
                                        </span>
                                    ) : null}
                                    <Badge count={0}>
                                        <Dropdown.Button
                                            overlay={messages}
                                            placement="bottomCenter"
                                            icon={
                                                <img
                                                    src={'/images/user/icons/message.svg'}
                                                    style={{
                                                        transform: latestMessages.find(msg => msg.is_read === false)?.is_read === false ? "translate(-10px, 0px)" : "translate(0px, 0px)",
                                                        marginBottom: '12px'
                                                    }}
                                                />
                                            }
                                        />
                                    </Badge>
                                </li>
                            </ul>
                        )}
                        <Tooltip
                            overlayClassName="headerTooltips"
                            placement="bottomRight"
                            trigger={['click']}
                            title={<HeaderProfileMenu user={user} history={props.history}/>}
                        >
                        <span
                            style={{
                                ...styles.userMenuStyles,
                                // ...styles.userMenuBorder,
                            }}
                            onClick={() => {
                                setShowDropdown(!showDropdown);
                            }}
                        >
                            <Avatar
                                color={Avatar.getRandomColor('sitebase', [
                                    'pink',
                                    'purple',
                                    'green',
                                    'orange',
                                ])}
                                name={user.name}
                                size={36}
                                round="50%"
                                style={{
                                    float: 'right',
                                    cursor: 'pointer',
                                }}
                            />
                        </span>
                        </Tooltip>
                    </div>
                    {/*<Tooltip*/}
                    {/*    overlayClassName="headerTooltips"*/}
                    {/*    overlay={HeaderMySchoolMenu}*/}
                    {/*    trigger={['click']}*/}
                    {/*    placement="bottomRight"*/}
                    {/*>*/}
                    {/*    <span*/}
                    {/*        onClick={() => {*/}
                    {/*            setShowDropdown(!showDropdown);*/}
                    {/*        }}*/}
                    {/*        style={{*/}
                    {/*            ...styles.userMenuStyles,*/}
                    {/*            ...styles.userMenuBorder,*/}
                    {/*            position: 'relative',*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <FontAwesomeIcon*/}
                    {/*            icon={['fal', 'map-marker']}*/}
                    {/*            style={{*/}
                    {/*                ...styles.userMenuIcon,*/}
                    {/*                position: 'relative',*/}
                    {/*                color: '#c1c1c1',*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*        <FontAwesomeIcon*/}
                    {/*            icon={['fas', 'graduation-cap']}*/}
                    {/*            style={{*/}
                    {/*                margin: 8,*/}
                    {/*                ...styles.userMenuIcon,*/}
                    {/*                position: 'absolute',*/}
                    {/*                color: '#c1c1c1',*/}
                    {/*                fontSize: '10px',*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </span>*/}
                    {/*</Tooltip>*/}
                    {/*<Tooltip*/}
                    {/*    overlay={*/}
                    {/*        <HeaderNotificationMenu*/}
                    {/*            notifications={notifications}*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*    overlayClassName="headerTooltips"*/}
                    {/*    trigger={['click']}*/}
                    {/*    placement="bottomRight"*/}
                    {/*>*/}
                    {/*    <span*/}
                    {/*        style={{*/}
                    {/*            ...styles.userMenuStyles,*/}
                    {/*        }}*/}
                    {/*        onClick={() => {*/}
                    {/*            setShowDropdown(!showDropdown);*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <NotificationsBell*/}
                    {/*            notifications={notifications}*/}
                    {/*            unreadCount={unreadCount}*/}
                    {/*        />*/}
                    {/*    </span>*/}
                    {/*</Tooltip>*/}
                    {/* {user.role === 'administrator' && (
                        <span
                            style={{
                                ...styles.userMenuStyles,
                            }}
                            onClick={() => {
                                setReportModalVisible(true);
                            }}
                        >
                            <FontAwesomeIcon
                                icon={['fas', 'bug']}
                                style={{
                                    ...styles.userMenuIcon,
                                    position: 'relative',
                                    color: '#c1c1c1',
                                }}
                            />
                        </span>
                    )} */}
                    {/*<Tooltip*/}
                    {/*    overlayClassName="headerTooltips"*/}
                    {/*    placement="bottomRight"*/}
                    {/*    trigger={['click']}*/}
                    {/*    title={*/}
                    {/*        <HeaderSetting*/}
                    {/*            user={user}*/}
                    {/*            setReportModalVisible={setReportModalVisible}*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    <span*/}
                    {/*        style={{*/}
                    {/*            ...styles.userMenuStyles,*/}
                    {/*            // ...styles.userMenuBorder,*/}
                    {/*        }}*/}
                    {/*        onClick={() => {*/}
                    {/*            setShowDropdown(!showDropdown);*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <SettingOutlined*/}
                    {/*            style={{*/}
                    {/*                ...styles.userMenuIcon,*/}
                    {/*                position: 'relative',*/}
                    {/*                color: '#c1c1c1',*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </span>*/}
                    {/*</Tooltip>*/}
                </Col>
            </Row>
        </div>
    );
};

export default HeaderMenuDesktop;

import React from 'react';
import './apiTable.scss';
import details from '../../../utilities/api-details.json';
import body from '../../../utilities/api-body.json';
import response from '../../../utilities/api-response.json';
import { ApiBaseURL } from '../../../constants/apiConstants';

const ApiTable = subject => {

    let __details = details[subject['subject']] || {};
    let __body = body[subject['subject']] || {};
    let __response = response[subject['subject']] || {};

    return (
        <div className="api-content">
            <h4>{__details?.info ?? ''}</h4>
            {__details?.hint && (
                <div style={{margin: '0 10px 6px 10px'}}>
                    <strong style={{color: 'red'}}>Hint 1: </strong>
                    <span>{__details.hint}</span>
                </div>
            )}
            {__details?.parameters.find(param => param.forRole) ? (
                <>
                    <div style={{margin: '0 10px 6px 10px'}}>
                        <strong style={{color: 'red'}}>Hint 2: </strong>
                        <span>The common parameters between the <strong>trainer</strong> and the <strong>learner</strong> are highlighted in <strong
                            style={{color: 'black'}}>Black</strong></span>
                    </div>
                    <div style={{margin: '0 10px 6px 10px'}}>
                        <strong style={{color: 'red'}}>Hint 3: </strong>
                        <span>The parameters of the <strong>trainer</strong> are highlighted in <strong
                            style={{color: '#50C878'}}>Green</strong></span>
                    </div>
                    <div style={{margin: '0 10px 6px 10px'}}>
                        <strong style={{color: 'red'}}>Hint 4: </strong>
                        <span>The parameters of the <strong>learner</strong> are highlighted in <strong
                            style={{color: 'blue'}}>Blue</strong></span>
                    </div>
                </>
            ) : null}
            <div className="table-content">
                <div className="table-url">
                    <p>URL</p>
                    <p>
                        {ApiBaseURL}/{__details.url}
                    </p>
                </div>
                <div className="table-parameters">
                    <p>Method</p>
                    <table>
                        <thead>
                        <tr>
                            <th rowSpan="10">{__details?.method}</th>
                        </tr>
                        </thead>
                    </table>
                </div>
                {__details?.parameters.length > 0 && (
                    <div className="table-parameters">
                        <p>Parameters</p>
                        <table>
                            <thead>
                            <tr>
                                <th rowSpan="10">Field</th>
                                <th>Required</th>
                                <th>Type</th>
                                <th>Default</th>
                                <th>Validation</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            {__details?.parameters.map((param, i) => {
                                return (
                                    <tr
                                        style={
                                            param?.forRole ?
                                                param?.forRole === 'learner' ?
                                                    {color: 'blue'} :
                                                    param?.forRole === 'trainer' ?
                                                        {color: '#50C878'} :
                                                        {color: 'black'} :
                                                {color: 'black'}
                                        }
                                        key={i}
                                    >
                                        <td>{param.field}</td>
                                        <td>{param.required}</td>
                                        <td>{param.type}</td>
                                        <td>{param.default}</td>
                                        <td>{param.validation}</td>
                                        <td>{param.description}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                )}
                {__details?.queries.length > 0 && (
                    <div className="table-response">
                        <p>Queries</p>
                        <table>
                            <thead>
                            <tr>
                                <th rowSpan="10">Field</th>
                                <th>Required</th>
                                <th>Type</th>
                                <th>Default</th>
                                <th>validation</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            {__details?.queries.map((param, i) => {
                                return (
                                    <tr>
                                        <td>{param.field}</td>
                                        <td>{param.required}</td>
                                        <td>{param.type}</td>
                                        <td>{param.default}</td>
                                        <td>{param.validation}</td>
                                        <td>{param.description}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            <div className="api-json">
                <h2>Headers</h2>
                <div className="json-code">
                    <pre>
                        {JSON.stringify(
                            {
                                'api-key': '##############################',
                            },
                            null,
                            2,
                        )}
                    </pre>
                </div>
            </div>
            {Object.keys(__body).length > 0 && (
                <div className="api-json">
                    <h2>Example Request</h2>
                    <div className="json-code">
                        <pre>{JSON.stringify(__body, null, 2)}</pre>
                    </div>
                </div>
            )}
            {Object.keys(__response).length > 0 && (
                <div className="api-json">
                    <h2>Example Response</h2>
                    <div className="json-code">
                        <pre>{JSON.stringify(__response, null, 2)}</pre>
                    </div>
                </div>
            )}
          {  (subject['subject'] === 'updateUser' || subject['subject'] === 'storeUser') && <div className="api-json">
                <h2>Example Error Response</h2>
                <div className="json-code">
                    <pre>
                        {JSON.stringify(
                            {
                                "status": 401,
                                "message": "The password may not be greater than 15 characters.",
                                "payload": {
                                    "errors": {
                                        "password": [
                                            "The password may not be greater than 15 characters."
                                        ]
                                    }
                                }
                            },
                            null,
                            2,
                        )}
                    </pre>
                </div>

                <h2>Another Example Error Response</h2>
                <div className="json-code">
                    <pre>
                        {JSON.stringify(
                            {
                                "status": 401,
                                "message": "The username has already been taken.",
                                "payload": {
                                    "errors": {
                                        "username": [
                                            "The username has already been taken."
                                        ]
                                    }
                                }
                            },
                            null,
                            2,
                        )}
                    </pre>
                </div>
            </div>
            }

      </div>
    );
};

export default ApiTable;

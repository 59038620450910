const moduleConstants = {
    FETCH_MODULES_REQUEST: 'FETCH_MODULES_REQUEST',
    FETCH_MODULES_SUCCESS: 'FETCH_MODULES_SUCCESS',
    FETCH_MODULES_REJECTED: 'FETCH_MODULES_REJECTED',
    FETCH_MODULE_REQUEST: 'FETCH_MODULE_REQUEST',
    FETCH_MODULE_SUCCESS: 'FETCH_MODULE_SUCCESS',
    FETCH_MODULE_REJECTED: 'FETCH_MODULE_REJECTED',
    FETCH_MODULE_ACTIVITIES_REQUEST: 'FETCH_MODULE_ACTIVITIES_REQUEST',
    FETCH_MODULE_ACTIVITIES_SUCCESS: 'FETCH_MODULE_ACTIVITIES_SUCCESS',
    FETCH_MODULE_ACTIVITIES_REJECTED: 'FETCH_MODULE_ACTIVITIES_REJECTED',
    ALLOCATE_MODULE_TO_USERS_REQUEST: 'ALLOCATE_MODULE_TO_USERS_REQUEST',
    ALLOCATE_MODULE_TO_USERS_SUCCESS: 'ALLOCATE_MODULE_TO_USERS_SUCCESS',
    ALLOCATE_MODULE_TO_USERS_REJECTED: 'ALLOCATE_MODULE_TO_USERS_REJECTED',
    SKIP_MODULE_REQUEST: 'SKIP_MODULE_REQUEST',
    SKIP_MODULE_SUCCESS: 'SKIP_MODULE_SUCCESS',
    SKIP_MODULE_REJECTED: 'SKIP_MODULE_REJECTED',
    SEARCH_MODULE_REQUEST: 'SEARCH_MODULE_REQUEST',
    SEARCH_MODULE_SUCCESS: 'SEARCH_MODULE_SUCCESS',
    SEARCH_MODULE_REJECTED: 'SEARCH_MODULE_REJECTED',
    SAVE_MODULE_REQUEST: 'SAVE_MODULE_REQUEST',
    SAVE_MODULE_SUCCESS: 'SAVE_MODULE_SUCCESS',
    SAVE_MODULE_REJECTED: 'SAVE_MODULE_REJECTED',
    RESET_MODULES: 'RESET_MODULES',
    FETCH_ALL_MODULES_REQUEST: 'FETCH_ALL_MODULES_REQUEST',
    FETCH_ALL_MODULES_SUCCESS: 'FETCH_ALL_MODULES_SUCCESS',
    FETCH_ALL_MODULES_REJECTED: 'FETCH_ALL_MODULES_REJECTED',

    FETCH_MODULE_DATA: 'FETCH_MODULE_DATA',
    FETCH_MODULE_DATA_SUCCESS: 'FETCH_MODULE_DATA_SUCCESS',
    FETCH_MODULE_DATA_REJECTED: 'FETCH_MODULE_DATA_REJECTED',
    CLEAR_MODULE: 'CLEAR_MODULE',
    SENDING_FEEDBACK: 'SENDING_FEEDBACK',
    SENDING_FEEDBACK_End: 'SENDING_FEEDBACK_End',
    SENDING_FEEDBACK_FAILEd: 'SENDING_FEEDBACK_FAILEd',
    RESET_SENDING_FEEDBACK: 'RESET_SENDING_FEEDBACK',
    SET_NEW_ACTIVITY_PERCENTAGE: 'SET_NEW_ACTIVITY_PERCENTAGE',
    MODULE_HARD_DELETE: 'MODULE_HARD_DELETE',
    SET_MODULE: 'SET_MODULE',
    MODULE_Activity_Completed: 'MODULE_Activity_Completed',
    FETCH_ZERO_RESULT_SEARCHES_AUDITS_REQUEST: 'FETCH_ZERO_RESULT_SEARCHES_AUDITS_REQUEST',
    FETCH_ZERO_RESULT_SEARCHES_AUDITS_SUCCESS: 'FETCH_ZERO_RESULT_SEARCHES_AUDITS_SUCCESS',
    FETCH_ZERO_RESULT_SEARCHES_AUDITS_REJECTED: 'FETCH_ZERO_RESULT_SEARCHES_AUDITS_REJECTED',
    FETCH_ZERO_RESULT_SEARCHES_REPORT_REQUEST: 'FETCH_ZERO_RESULT_SEARCHES_REPORT_REQUEST',
    FETCH_ZERO_RESULT_SEARCHES_REPORT_SUCCESS: 'FETCH_ZERO_RESULT_SEARCHES_REPORT_SUCCESS',
    FETCH_ZERO_RESULT_SEARCHES_REPORT_REJECTED: 'FETCH_ZERO_RESULT_SEARCHES_REPORT_REJECTED',
};

export default moduleConstants;
